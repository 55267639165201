import React, { useEffect, useState } from "react"
import { OPDataModel, OPHardCoded, SelectedSection } from "./OPDataModel";
import { WhitespaceAPI } from "../../helpers/whitespace";
import { Market } from "../../helpers/Market";
import { SummaryFilter } from "../../components/SummaryFilter";
import { RiskGrid } from "../../components/RiskGrid";
import { NotificationManager } from 'react-notifications'
import { utils } from "../../FromElsewhere/utils";
import { riskFilter } from "../../helpers/riskFilter";
import { JMRC } from "../../rebrowse/jmrc";
import { MSStorer } from "../../rebrowse/multi.section.storer";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CombinedSets } from "../../helpers/CombinedSets";
import { OPEditor } from "./OPEditor";

let market : Market;	// = Market.makeUsingAPI( WhitespaceAPI );
const dataModel = new OPDataModel( console.log );

const DEFAULT_DECIC = "ICE294C816-C061-45B9-B443-651C5F44480B";
const DEFAULT_FACIC = "IC24FA2188-6FD4-4200-8DB1-1DD208A5EB5F";

export const OPDecs = () => {
    const MyStage = {
		PICKDECLARATION: "PICKDECLARATION",
		PICKFACILITY: "PICKFACILITY",
		PICKSECTIONS: "PICKSECTIONS",
		CREATELINES: "CREATELINES"		
	}
    const mockSectionDoc = () : MSStorer.Root => {
		const provenance = mockProvenance();
		const section = mockSection()
		const result: MSStorer.Root = { _id: "", _rev: "", associatedRootID: "", channels: [], createdAt: "", updatedAt: "", type: "", sections: [ section ], provenance:provenance};
		return result;
	}
	const mockProvenance = () => {
		const result : MSStorer.Provenance = {dataHash: "",provHash: "",system: "",userID: "",version: "",writtenAt: ""};
		return result;
	}
	const mockSection = () : MSStorer.Section => {
		return { index: 0, leadUnderwriter: "", leadUnderwriterChannel: "", multiSectionId: OPHardCoded.SECTION0,  multiSectionName: "Single Section" }
	}

    const [dmMessage, setDMessage] = useState("Data Model is empty");
    const [showLocalHost, setShowLocalHost] = useState( window.location.href.includes( "localhost") );
	const [defaultDecGrail, setDefaultDecGrai] = useState( localStorage['OPDecsDefaultDecGrail'] || "" );
	const [defaultFacGrail, setDefaultFacGrai] = useState( localStorage['OPDecsDefaultFacGrail'] || "");
	const [stage, setStage] = useState(MyStage.PICKDECLARATION)
	const [declarations, setDeclarations] = useState<any[]>([]);
    const [declarationMRC, setDeclarationMRC] = useState<JMRC.Root>();
    const [declarationSections, setDeclarationSections] = useState<MSStorer.Root>(mockSectionDoc());
	const [declarationSectID,setDeclarationSectID] = useState( OPHardCoded.NOSECTION );
	const [declarationLineSets, setDeclarationLineSets] = useState<CombinedSets.Root[]>([]);
	const [declarationSelectedSection,setDeclarationSelectedSection]= useState<SelectedSection>( { index : -1, multiSectionId : "", multiSectionName : ""} )
	const [facilities, setFacilities] = useState<any[]>([]);
	const [facilityMRC, setFacilityMRC] = useState<JMRC.Root>();
	const [facilitySectID,setFacilitySectID] = useState( OPHardCoded.NOSECTION );
	const [facilitySections, setFacilitySections] = useState<MSStorer.Root>(mockSectionDoc());
	const [facilityLineSets, setFacilityLineSets] = useState<CombinedSets.Root[]>([]);
	const [payload,setPayload] = useState<any>( {} );
	const [totalPercentage, setTotalPercentage] = useState( "");

    const [myDataModel, setMyDataModel] = useState<OPDataModel|null>(null);
    const feedbackFromDataModel = ( msg ) => {
		setDMessage( msg );
	}

    useEffect(() => {
		WhitespaceAPI.get( "/api/shared/corporate").then( response => {
			market = Market.makeUsingCorporates( response.data );
			dataModel.callback = feedbackFromDataModel;
			dataModel.market = market;
			console.log( `market has ${dataModel.market.corporates.length} corporates`);
		})
	}, []);

    const doShortcut = () => {
        pickDeclaration( { id : DEFAULT_DECIC }, doShortcut2 );
    }
    const doShortcut2 = () => {
        pickFacility( { id : DEFAULT_FACIC }, doShortcut3 );
    }
    const doShortcut3 = () => {
        console.log( "doShortcut3");
    }

    const doDeclarationSummary = ( grail ) => {
		localStorage[ "ShowDecsDefaultDecGrail" ] = grail;
        grail = grail.trim();
		const statuses = [ "FirmOrder" ];
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = {statuses : statuses };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
		console.log( payload );
        setDeclarations( [] );
		WhitespaceAPI.post( '/api/summary', payload, { silent: true } ).then( async response => {
			console.log( "POST /api/summary complete");
			const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
			setDeclarations( sumRisks );
		});
    }

    const pickDeclaration = ( risk, nextFn ) => {
		const riskID = risk.id + "::FO";
		const msDocID = risk.id + "::MS";
		WhitespaceAPI.get( `/api/risks/${riskID}`).then( response => {
			if( response.data && response.data.control ) {
				const x : JMRC.Root = response.data;
				dataModel.declarationRev = x._rev;
				setDeclarationMRC( x );
								
				WhitespaceAPI.get( `/api/documents/${msDocID}`, { silent: true } ).then( response => {
					let sectDoc : MSStorer.Root = mockSectionDoc();
					if( response.data && response.data.sections && response.data.sections.length > 0 ) {
						sectDoc = response.data;
					}
					setDeclarationSections( sectDoc );	
					WhitespaceAPI.get( `/api/lines/${risk.id}/combinedSets`).then( response => {
						const allSets : CombinedSets.Root[] = response.data;
						const writtenSets = allSets.filter( ls => { return ls.type == "RWWrittenLineSet"} )
						setDeclarationLineSets( writtenSets );					
						console.log( `setDeclarationLineSets with ${writtenSets.length} items`);
						setStage( MyStage.PICKFACILITY );
						if( nextFn ) {
							nextFn();
						}
					});



				});
			}
		});
    }

	const summariseLineSets = ( s : CombinedSets.Root[] ) : string => {
		return s.length > 0 ? s.length.toString() + utils.makePlural( s.length, ` Line`) : "No Lines";
	}

    const doFacilitySummary = ( grail ) => {
		localStorage[ "ShowDecsDefaultFacGrail" ] = grail;
        grail = grail.trim();
        NotificationManager.success( grail ? `Fetching for ${grail}` : "Fetching first 60 risks");
        const payload: any = {types : ["Facilities"] };
        if( grail ) {
            if( utils.validUMR( grail ) ) {
                payload.umrSearch = grail;
            } else {
                payload.nameSearch = grail;
            }
        }
		console.log( payload );
        setDeclarations( [] );
		WhitespaceAPI.post('/api/summary', payload, { silent: true } ).then( response => {
			console.log( "POST /api/summary complete");
			const sumRisks = riskFilter.groupRisksSummary( response.data ); // , { status : statuses[0]} );
			setFacilities( sumRisks );
		});
    }

    const pickFacility = ( risk, nextFn ) => {
		const riskID = risk.id + "::SIGNED";
		const msDocID = risk.id + "::MS";
		dataModel.facID = risk.id;

		WhitespaceAPI.get( `/api/risks/${riskID}`).then( response => {
			if( response.data && response.data.control ) {
				console.log( response.data.control );
				const x : JMRC.Root = response.data;
				setFacilityMRC( x );

				WhitespaceAPI.get( `/api/documents/${msDocID}`, { silent: true }).then( response => {
					let sectDoc : MSStorer.Root = mockSectionDoc();
					if( response.data && response.data.sections && response.data.sections.length > 0 ) {
						sectDoc = response.data;
					}
					setFacilitySections( sectDoc );	
					dataModel.facSections = sectDoc;	
					setFacilitySectID( OPHardCoded.NOSECTION );
					gotoStage( MyStage.PICKSECTIONS );
					if( nextFn ) {
						nextFn();
					}

				});
			}
		});
    }

    const pickedTheSections = () => {	
		console.log( `Picked the sections ${declarationSectID} ${facilitySectID}`);
		if( declarationSectID == OPHardCoded.NOSECTION || facilitySectID == OPHardCoded.NOSECTION ) { return; }
		dataModel.facSectionID = facilitySectID;

		let selectedSection : SelectedSection = { index : -1, multiSectionId : "", multiSectionName : ""};
		if( declarationSectID != OPHardCoded.SECTION0 ) {
			const sect = declarationSections.sections.find( s => { return s.multiSectionId == declarationSectID });
			if( sect ) {
				selectedSection = { index : sect.index, multiSectionId : sect.multiSectionId, multiSectionName : sect.multiSectionName };				
			}
		} 
		setDeclarationSelectedSection( selectedSection );
		WhitespaceAPI.get( `/api/lines/${utils.rootIC(facilityMRC?._id || "")}/combinedSets`).then( response => {
			const allSets : CombinedSets.Root[] = response.data;
			const signedSets = allSets.filter( ls => { return ls.type == "RWSignedLineSet"} );
			dataModel.facilityLineSets = signedSets;
			dataModel.filterAndSortSignedSets();
			setFacilityLineSets( signedSets );
			console.log( `setFacilityLineSets with ${signedSets.length} items`);

			WhitespaceAPI.get( `/api/risks/${declarationMRC?._id}`).then( response => {
				if( response.data && response.data.control ) {
					const x : JMRC.Root = response.data;
					dataModel.buildPayload( declarationSectID, facilitySectID, selectedSection );
					setPayload( dataModel.recordLinesPayload );
					setTotalPercentage( dataModel.getTotalPercentage() );
					setMyDataModel( dataModel );
					setStage( MyStage.CREATELINES );
				}
			});	
		});			
	}

    const gotoStage = ( nextStage ) => {
		if( nextStage == MyStage.PICKSECTIONS ) {
			setDeclarationSectID( (declarationSections?.sections.length || 0 ) > 1 ? OPHardCoded.NOSECTION : OPHardCoded.SECTION0 );
			setFacilitySectID( (facilitySections?.sections.length || 0 ) > 1 ? OPHardCoded.NOSECTION : OPHardCoded.SECTION0 );
		}
		setStage( nextStage );
	}

	const updateMe = ( ob : any ) => {
		const itmidx = ob.itmidx;
		const lidx = ob.lidx;
		const iidx = ob.iidx;
		const percentage = ob.percentage;
		if( myDataModel ) {
			myDataModel.recordLinesPayload.items[ itmidx ].lines[lidx].impressions[iidx].writtenPercentage = percentage.toString();	
			console.log( `updateMe items[ ${itmidx} ].lines[${lidx}].impressions[${iidx}] to ${percentage.toString()}`);
			setTotalPercentage( myDataModel.getTotalPercentage() );
			console.log( `setTotalPercentage ${myDataModel.getTotalPercentage()} -> ${totalPercentage}`)
		}
	}
	const preview = () => {
		setPayload( myDataModel?.recordLinesPayload || {} );
	}
	const showLineSets = () => {
		setPayload( myDataModel?.facilityLineSets || {} );
	}
	const doSave = () => {
		console.log( "checkAndSave");
		const url = `/api/risks/${declarationMRC?._id || ""}/recordLine`;
		if( !myDataModel ) {
			NotificationManager.console.error("DataModel not ready for save");
			return;
		}
		const payload = myDataModel.removeZeroEntries();
		if( !payload.items ) {
			NotificationManager.console.error("No non-zero lines to save");
			return;
		}
		WhitespaceAPI.post( url, payload ).then( response => {
			setPayload( response.data );
			NotificationManager.success( response.data.msg || "Data saved");
			WhitespaceAPI.get( `/api/lines/${utils.rootIC(declarationMRC?._id || "")}/combinedSets`).then( response => {
					const allSets : CombinedSets.Root[] = response.data;
					const writtenSets = allSets.filter( ls => { return ls.type == "RWWrittenLineSet"} )
					setDeclarationLineSets( writtenSets );					
			});
		});
	}

	
	const lineSetViewer = ( wrls, idx ) => {
		return ( 
			<div key={idx}>
			{wrls.contents.map( ( c, idx2 ) => {
				//const sectionDesc = c.sectionIdentifiers?.length ? "Section " + c.sectionIdentifiers.join( ", ") : "No Section";
				return( 
					<div key={idx2}>
					<div><b>{c.businessUnit}</b></div> 
					{c.impressions.map( ( imp, idx3 ) => {
						return (
							<div key={idx3}><b>{imp.writtenLinePercentageString}%</b> {imp.stamp.bureauMarket} {imp.stamp.bureauMarketCode} {imp.stamp.bureauSubMarket} {imp.stamp.businessUnit}%&nbsp;Refs:
							{
								imp.uwRefs.map( ( ref, idx4 ) => {
									return ( <span key={idx4}>&nbsp;{ref}</span>)
								})
							}
							&nbsp;Risk Codes:
							{
								imp.riskCodes.map( ( rc, idx4 ) => {
									return ( <span key={idx4}>&nbsp;{rc.code}</span>)
								})
							}
							</div>
						)
					})}
					</div>
				)
			})}
			</div>
		)
	}

    return (
        <>
		{stage == MyStage.PICKDECLARATION &&
			<>
			<h2>Step 1 : Choose the risk</h2>
			<div>
			<SummaryFilter onSearch={doDeclarationSummary} grailDefault={defaultDecGrail} ></SummaryFilter>
			<RiskGrid input={declarations} pickFn={pickDeclaration} singleLine={true} />
			</div>
			</>
		}
		{stage == MyStage.PICKFACILITY &&
			<>
			<h2>Step 2 : Choose the facility</h2>
			<div><b>Risk</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSections?.sections.length || "No"} Section(s) :
				&nbsp;{summariseLineSets(declarationLineSets)} :
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			<div><Button onClick={() => gotoStage(MyStage.PICKDECLARATION)}>Back</Button></div>
			<SummaryFilter onSearch={doFacilitySummary} grailDefault={defaultFacGrail} ></SummaryFilter>
			<RiskGrid input={facilities} pickFn={pickFacility} singleLine={true} /> 
			</>
		}
		{stage == MyStage.PICKSECTIONS &&
			<>
			<h2>Step 3 : Choose the sections</h2>
			<div><b>Risk</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSections?.sections.length || "No"} Section(s) :
				&nbsp;{summariseLineSets(declarationLineSets)} :
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			<div className='Above12'><b>Facility</b> {facilityMRC?.control.insuredName} : {facilityMRC?.control.umr} : 
				&nbsp;{facilitySections?.sections.length || "No"} Section(s) :
				&nbsp;{summariseLineSets(facilityLineSets)} :
				&nbsp;<span className='LightGray'>{facilityMRC?._id}</span></div>

			<div className='Above12'>
				<Button onClick={() => setStage(MyStage.PICKFACILITY)}>Back</Button>
				<span className={declarationSectID == OPHardCoded.NOSECTION || facilitySectID == OPHardCoded.NOSECTION ? 'HideMe' : ''}>
				&nbsp;<Button onClick={pickedTheSections}>Next</Button></span>
			</div>
			<Container>
				<Row className='Above12'>
					<Col xs={6}><h3>Declaration</h3></Col>
					<Col xs={6}><h3>Facility</h3></Col>
				</Row>
				<Row>
					<Col xs={6}>
						{declarationSections.sections.map( (s, idx) => { 
							return ( <div className='SectPicker' onClick={() => { setDeclarationSectID(s.multiSectionId) }} key={idx}>
								{s.multiSectionId} : {s.multiSectionName}
								&nbsp;<span className={s.multiSectionId == declarationSectID ? "ShowMe" : "HideMe"}>SELECTED</span>
							</div>)} )
						}
					</Col>
					<Col xs={6}>
						{facilitySections.sections.map( (s, idx) => { 
							return ( <div className='SectPicker' onClick={() => { setFacilitySectID(s.multiSectionId) }} key={idx}>
								{s.multiSectionId} : {s.multiSectionName} 
								&nbsp;<span className={s.multiSectionId == facilitySectID ? "ShowMe" : "HideMe"}>SELECTED</span>
							</div>)} )
						}
					</Col>
				</Row>
			</Container>
			</>
		}
        {stage == MyStage.CREATELINES && myDataModel != null &&
			<>
			<h2>Step 4 : Adjust percentages and save</h2>
			<div><b>Risk</b> {declarationMRC?.control.insuredName} : {declarationMRC?.control.umr} : 
				&nbsp;{declarationSectID == OPHardCoded.SECTION0 ? "" : `Section ${declarationSectID} ${declarationSelectedSection.multiSectionName} of ` }{declarationSections?.sections.length || "No"} Section(s) : 
				&nbsp;{summariseLineSets(declarationLineSets)} :
				&nbsp;<span className='LightGray'>{declarationMRC?._id}</span></div>
			{OPDataModel.countImpressions(declarationLineSets, declarationSectID) > 0 && 
			<div className="Above12">
				<div className="WarningText">Risk has {OPDataModel.countImpressions(declarationLineSets, declarationSectID)} line(s) {declarationSectID == OPHardCoded.NOSECTION ? "" : `on section ${declarationSectID} ${declarationSelectedSection.multiSectionName}`}</div>
				{ OPDataModel.filterAndSortLineSets(declarationLineSets, declarationSectID).map( (lineset,idx) => {
					return lineSetViewer( lineset, idx )
				})}
			</div>

			}
			<div className='Above12'><b>Facility</b> {facilityMRC?.control.insuredName} : {facilityMRC?.control.umr} : 
				&nbsp;{facilitySectID == OPHardCoded.SECTION0 ? "" : `Section ${facilitySectID} of ` }{facilitySections?.sections.length || "No"} Section(s) : 
				&nbsp;{summariseLineSets(facilityLineSets)} :
				&nbsp;<span className='LightGray'>{facilityMRC?._id}</span></div>
			<div className='Above12'>
				<Button onClick={() => gotoStage(MyStage.PICKSECTIONS)}>Back</Button>
			</div>

			<div className="WarningText Above12 Below12">Note: Line amount(s) below can be edited and must reflect the correct amount(s) for the selected contract</div>
			<Container>
			{myDataModel.recordLinesPayload.items.map( (s,idx) => {
				return ( 
					<div key={idx}><OPEditor index={idx} lineSet={s} updateParent={updateMe}/></div>
				)}
			)}
			<Row className='Above2' >
				<Col xs={2}><input className='FullWidth RightAlign' type="text" value={totalPercentage} readOnly/></Col>
				<Col xs={10}>Total Percentage</Col>
			</Row>
			</Container>

			<div className="Above12">
				<Button onClick={doSave}>Apply</Button> 
			</div>

			</>
		}       
		{ showLocalHost && 
			<div className="Above12">
			<div><b>localhost</b> <span className="MyLink" onClick={() => {setShowLocalHost(false);}}>Hide</span>
			&nbsp;<span className="MyLink" onClick={doShortcut}>Shortcut</span>
			&nbsp;<span className="MyLink" onClick={showLineSets}>showLineSets</span>
			&nbsp;<span className="MyLink" onClick={preview}>Preview</span>
			</div>
			<h3>Payload</h3>
			<textarea className='JSONViewer' readOnly value={JSON.stringify(payload,null,4)} ></textarea>
			</div>		
		}
        </>
    )


}